<template>
  <section class="MyTeamCreate-View">
    <SpaceDescription>
      <Headline :size="1" class="mb-2">Create person</Headline>
      <p class="InputLayout__label_subtext text-uppercase font-weight-bold">* Mandatory Field</p>
    </SpaceDescription>

    <div v-if="!isPersonCreatedSuccessful">
      <PersonForm
        :model="model"
        :request="request"
      />
      <SectionControls
        button-left-text="Back"
        button-left-type="secondary"
        @button-left-click="navigateToPreviousPage()"
        :button-right-text="'Create Person'"
        :button-right-disabled="model.containsEmptyKeys(['firstName', 'lastName', 'birthDate'])"
        @button-right-click="submit()"
        :policyText="policyText"
      />
    </div>

    <div v-if="isPersonCreatedSuccessful">
      <SectionStatus message="You've successfully created a person!"/>
      <SectionControls
        :button-left-text="'Back to Overview'"
        @button-left-click="navigateToOverview()"
        :button-right-text="'Create another person'"
        @button-right-click="initialiseState()"
      />
    </div>

    <DuplicatePersonPopup
        v-if="showPopup"
        contactType="contact"
        :duplicatePersons="duplicatePersons"
        @closePopup="onClose"
        @createDuplicate="createDuplicate"
    />

  </section>
</template>

<script>
import {TeamMemberModel} from "@/classes/models/TeamMember.model";
import {DtoApiCreatePersonRequest} from "@/classes/dto/api/person/dto.api.create-person.request";
import SpaceDescription from "@/components/SpaceDescription/SpaceDescription.component";
import Headline from "@/components/Headline/Headline.component";
import SectionStatus from '@/components/Section/SectionStatus.component';
import SectionControls from '@/components/Section/SectionControls.component';
import PersonForm from '@/components/Form/PersonForm.component';
import RouterNames from "@/router/route.names";
import get from 'lodash.get';
import DuplicatePersonPopup from '@/components/Popup/DuplicatePersonPopup.component';
import {TeamMembersCollection} from "@/classes/collections/TeamMembers.collection";

export default {
  name: 'MyTeamCreate-View',
  data: () => ({
    model: null,
    request: null,
    showPopup: false,
    duplicatePersons: new TeamMembersCollection(),
  }),
  components: {
    SpaceDescription,
    Headline,
    PersonForm,
    SectionStatus,
    SectionControls,
    DuplicatePersonPopup
  },
  computed: {
    isRequestSent() {return !!get(this, 'request.$status')},
    permissions() {return this.$store.getters['user/permissions']},
    isPersonCreatedSuccessful() {
      if (!this.request) return false;
      if ((this.request.$status === 200) && this.request.isSuccessful) return true;
      if ((this.request.$status === 200) && this.request.isFailure && this.request.isDuplicate) {
        this.duplicatePersons = this.toPersonCollection(this.request.data.duplicates);
        this.showPopup = true;
        return false;
      }
      return false;
    },
    policyText() {
      return 'By clicking <b>CREATE PERSON</b> and submitting this form I confirm that the information either relates' +
          ' to myself and/or I have the authority and permission to submit the data on behalf of the person named above\n' +
          ' and such persons have been made aware of, and agree to, the terms' +
          ' of the <a href=\''+window.boUrl+'/policy'+'\' target="_blank">AFC Data Protection Policy.</a>'
    }
  },
  watch: {
    permissions() {this.checkPermissions()}
  },
  created() {
    this.checkPermissions();
    this.initialiseState();
  },
  methods: {
    checkPermissions() {
      const permitted = this.permissions.includes('persons:createAPI') && this.$store.getters['user/haveAccessToAccreditation'];
      if (!permitted) this.$router.push({name: RouterNames.Home})
    },
    initialiseState() {
      this.model = new TeamMemberModel();
      this.request = new DtoApiCreatePersonRequest();
    },
    navigateToOverview() {
      const name = RouterNames.IndexRoutes.DashboardRoutes.PersonRoutes.PersonList;
      this.$router.push({name});
    },
    async submit(skipDuplicateCheck = false) {
      const organizationId = this.$store.state.user.account.id;
      const essentialsContainEmptyValues = this.model.containsEmptyKeys(['firstName', 'lastName', 'birthDate']);
      if (essentialsContainEmptyValues) return null;

      this.request = await this.request.$createSingle(organizationId, this.model, skipDuplicateCheck);

      if(this.$route.params.fromAccreditationCreatePage && !this.request.$error) {
        this.navigateToCreateAccreditation()
      }
    },
    onClose() {
      this.showPopup = false;
    },
    createDuplicate() {
      this.onClose();
      this.submit(true);
    },
    toPersonCollection(duplicates) {
      const collection = new TeamMembersCollection();
      collection.models = duplicates.map(item => {
        const model = new TeamMemberModel();
        [
          'firstName',
          'lastName',
          'birthDate',
          'email',
        ]
                .forEach(key => model[key] = item[key]);
        model.id = item.personId;
        return model;
      });
      return collection;
    },
    navigateToCreateAccreditation() {
      this.$router.push({name: RouterNames.IndexRoutes.DashboardRoutes.AccreditationRequest})
    },
    navigateToPreviousPage() {
      if(this.$route.params.fromAccreditationCreatePage) {
        this.navigateToCreateAccreditation()
      } else {
        this.navigateToOverview();
      }
    }
  }
}
</script>

<style lang="scss">
@import "../../../../styles/mixins/ellipsis.mixin";

.MyTeamCreate-View {
  .headline-component {
    margin-top: 0 !important
  }
}
</style>
